<template>
  <div class="face-recognition" ref="vehicleOnlineRate">
    <div class="function-buttons" ref="functionButtons" v-show="false">
      <el-button type="primary" size="small"></el-button>
    </div>
    <div class="analysisForm form-area" ref="formA">
      <!-- <analysisForm @getFormVal="onSubmit" :faceShow="true"></analysisForm> -->
      <el-form
        :model="form"
        class="demo-form-inline"
        label-position="right"
        label-width="86px"
        :rules="rules"
        ref="form"
      >
        <el-form-item label="公司车队：">
          <companySelectForSearch
            ref="companySelectForSearch"
            :multiple="true"
            :searchable="true"
            @getValue="getGroupIds"
          />
        </el-form-item>
        <el-form-item label="选择车辆：">
          <!-- <car-tree @getData="getVehIds" ref="carTree"></car-tree> -->
          <el-input
          v-model="form.keyWord"
          type="input"
          placeholder="请输入"
        ></el-input>
        </el-form-item>
        <el-form-item label="选择时间：" prop="time">
          <el-date-picker
            cellClassName="timeRangePicker"
            v-model="form.time"
            type="daterange"
            align="right"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="timestamp"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="类型：">
          <el-select v-model="form.status" placeholder="全部" clearable>
            <el-option
              v-for="item in typeList"
              :label="item.dictName"
              :value="item.dictValue"
              :key="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table id="outputTable" :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="licensePlateNum" label="车牌号"></el-table-column>
      <el-table-column prop="status" label="类型" :formatter="formatNull">
        <template slot-scope="scope">
          {{ scope.row.status | filterEvaluateStatus }}
        </template>
      </el-table-column>
      <el-table-column prop="workNum" label="驾驶员工号"></el-table-column>
      <el-table-column prop="driverName" label="驾驶员姓名"></el-table-column>
      <el-table-column prop="signDate" label="验证时间" width="160"></el-table-column>
      <el-table-column prop="result" label="验证结果" :formatter="formatNull">
        <template slot-scope="scope">
          {{ scope.row.result | filterResult }}
        </template>
      </el-table-column>
      <el-table-column prop="longitude" label="位置" width="200">
        <template slot-scope="scope">
          {{ scope.row.longitude}}-{{scope.row.latitude}}
        </template>
      </el-table-column>
      <el-table-column prop="velocity" label="速度">
        <template slot-scope="scope">
          {{ scope.row.velocity}}km/h
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="onCheckDetail(scope.row)"
            type="text"
            size="small"
            sort="primary"
            >查看照片</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>

    <!-- 识别照片弹窗 -->
    <el-dialog
      title="照片详情"
      :visible.sync="dialogVisible"
      @close="onDialogClose"
      width="70%"
    >
      <el-row :gutter="20" v-if="imgArr.driverPhoto || imgArr.photoUrls">
        <el-col :span="16">
          <p>登签照片</p>
          <div class="grid-content bg-purple driver-photo">            
            <div class="driver-photo-box" v-for="(ele,index) in imgArr.photoUrls" :key="index">
              <img :src="ele" alt="">
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <p>司机照片</p>
          <div class="grid-content bg-purple driver-photo">
            <div class="driver-photo-box">
              <img :src="imgArr.driverPhoto" alt="">
            </div>
          </div>
        </el-col>
      </el-row>
      <div v-else style="line-height:200px;text-align:center;">
        暂无数据
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="()=>dialogVisible=false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="()=>dialogVisible=false"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import analysisForm from '@/components/analysisForm/analysisForm.vue'
import { faceSignList,faceSignMedias } from '@/api/lib/taxi-api.js'
import { YHTools } from '@/assets/js/Tools.js'
import { getCurent,formatDay, getRangeDay,getStartDate,checkTimeLimitDay } from '@/common/utils/index'
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'

export default {
  name: 'vehicleOnlineRate',
  components: {
    carTree,
    companySelectForSearch
  },
  data () {
    // 不能超前选择
    const validateDateForward = (rule, value, callback) => {
      if (this.chooseToday) {
        if (
          new Date() <= value[0] ||
          new Date() <= value[1]
        ) {
          callback(new Error('不能选择未来时间'))
        } else {
          callback();
        }
      } else {
        if (new Date(getStartDate()) <= value[0] || new Date(getStartDate()) <= value[1]) {
          callback(new Error("不能选择今天及未来时间"));
        } else {
          callback();
        }
      }
    };
    const validateDate1 = (rule, value, callback) => {
      if (!checkTimeLimitDay(value[0], value[1], this.timeLimit)) {
        callback(new Error(`只能查询${this.timeLimit}天内的数据`));
      } else {
        callback();
      }
    };
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = getRangeDay(this.chooseToday)[1];
              const start = getRangeDay(this.chooseToday)[1] - 3600 * 1000 * 24 * 7;
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick (picker) {
              const end = getRangeDay(this.chooseToday)[1];
              const start = getRangeDay()[1] - 3600 * 1000 * 24 * 30;
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableHeight: 0,
      tableData: [],
      form: {
        status: '',
        companyIds: [],
        keyWord:'',
        currentPage: 1,
        pageSize: 10,
        time: getRangeDay(this.chooseToday),
      },
      typeList: [],
      chooseToday: true,//能选择今天
      timeLimit:31,
      rules: {
        time: [
          { required: true, message: "请选择时间范围", trigger: "blur" },
          { validator: validateDateForward, trigger: "change" },
          { validator: validateDate1, trigger: "change" },
        ]
      },
      typeList: [
        {
          dictValue:1,
          dictName:'人脸签到'
        },
        {
          dictValue:2,
          dictName:'人脸签退'
        },
        {
          dictValue:3,
          dictName:'定时验证'
        }
      ],
      total: 0,
      dialogVisible:false,
      imgArr:{
        photoUrls:[],
        driverPhoto:''
      },
    }
  },
  filters:{
    filterEvaluateStatus(item){
      if(item==1){
        return '人脸签到'
      }else if(item==2){
        return '人脸签退'
      }else if(item==3){
        return '定时验证'
      }else{
        return item
      }
    },
    filterResult(item){
      if(item==1){
        return '成功'
      }else{
        return '失败'
      }
    }
  },
  methods: {
    onDialogClose(){
      this.dialogVisible=false
    },
    onCheckDetail(row){
      const data ={
        terminalPhoneNo:row.terminalPhoneNo,
        workNum:row.workNum,
        signDate:row.signDate
      }
      faceSignMedias(data).then(res=>{
        console.log(res)
        this.dialogVisible=true
        this.imgArr = res.data
      }).catch(err=>{
        console.log(err)
      })
    },
    /** 数据为空默认显示- */
    formatNull (row, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : cellValue
    },
    // 获取车组数据
    getGroupIds (val) {
      this.form.companyIds = [...val]
    },
    // 获取车辆数据
    getVehIds (val) {
      this.form.vehicleNoList = [...val]
    },
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.vehicleOnlineRate.clientHeight
      let buttonsHeight = this.$refs.functionButtons.clientHeight
      let formHeight = this.$refs.formA.clientHeight
      let paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight = wholeHeight - 16 - buttonsHeight - 16 - formHeight - paginationHeight - 10
    },
    // 提交
    onSubmit (value) {
      this.form.companyId = value.companyId || null
      this.form.vehicleNos = value.vehicleNos
      this.form.beginTime = value.beginTime
      this.form.endTime = value.endTime
      this.getDataList()
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getDataList()
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.getDataList()
    },
    // 获取列表
    getDataList () {
       this.$refs.form.validate((valid) => {
         if(valid){
           const data ={
              pageSize:this.form.pageSize,
              currentPage:this.form.currentPage,
              beginTime: formatDay(this.form.time[0]).split(" ")[0] + " 00:00:00",
              endTime: formatDay(this.form.time[1]).split(" ")[0] + " 23:59:59",
              companyIds:this.form.companyIds,
              status:this.form.status,
              keyWord:this.form.keyWord
            }
            faceSignList(data).then(res => {        
            if (res.code === 1000) {
              if (res.data.list.length > 0) {
                this.total = res.data.total
                this.tableData = res.data.list;
              } else {
                this.tableData = []
              }
            }
          })
         }
       })
      
      
    },
    /** 时长 */
    formatTime (row, column, cellValue) {
      return cellValue == null || cellValue === '' || cellValue === 0 ? '-' : YHTools.FormatTimeBySeconds(cellValue * 60, true)
    }

  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.face-recognition {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}

.theme-project-taxi {
  .face-recognition {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
    /deep/.analysisForm .el-form {
      /**浏览器宽度 */
      @media screen and (max-width: 1710px) {
        grid-template-columns: 21% 21% 35% 20%;
      }

      /**浏览器宽度 */
      @media screen and (max-width: 1366px) {
        grid-template-columns: 21% 21% 35% 20%;
      }
    }
  }
}
.driver-photo{
  margin-top: 20px;
  display: flex;
  .driver-photo-box{
    margin-left: 10px;
    img{
      height: 280px;
    }
  }
}
</style>
